import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Dashboard from "../../components/Dashboard"
import DashboardTransaction from "../../components/Dashboard/Transaction"

const DashboardTransaksiPage = () => (
  <Layout noFooter>
    <SEO title="Riwayat Transaksi" />
    <Dashboard chosen="Payment" noNotif={true}>
      <DashboardTransaction />
    </Dashboard>
  </Layout>
)

export default DashboardTransaksiPage
