import React, { useState, useEffect } from "react"

import { Styles } from "./style"

import { getAllTransaction } from "services/order"
import { formatDateTime, formatMoney } from "services/utils"

export default function TransactionComponent() {
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    getAllTransaction().then(resp => {
      resp.data?.sort((a, b) => {
        return new Date(b.transaction_time) - new Date(a.transaction_time)
      })
      setTransactions(resp.data)
    })
  }, [])

  return (
    <Styles>
      <h2>List Transaksi</h2>
      <hr />
      <div>
        {transactions?.length > 0 ? (
          transactions.map((val, index) => {
            const kelas = val.pesanan.paket_pembayaran.kelas
            return (
              <div key={index} className="one-transaction">
                <div className="row">
                  <div className="col-md-4">
                    <h5>{kelas.name}</h5>
                    <div>
                      by{" "}
                      <span style={{ color: "#54b2e5" }}>
                        {kelas.owner.first_name + " " + kelas.owner.last_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      Amount:{" "}
                      <span style={{ color: "#51b848" }}>
                        Rp {formatMoney(val.gross_amount)}
                      </span>
                    </div>
                    <div>Payment type: {val.payment_type}</div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      Status:{" "}
                      <span style={{ color: "#54b2e5" }}>
                        {val.final_status}
                      </span>
                    </div>
                    <div>{formatDateTime(val.transaction_time)}</div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="gray">Nothing to show</div>
        )}
      </div>
    </Styles>
  )
}
