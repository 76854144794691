import styled from "styled-components"

export const Styles = styled.div`
  padding-top: 24px;
  max-width: 1024px;
  margin: auto;

  h2 {
    margin-top: 1.5rem;
    font-family: "Source Serif Pro", serif;
    font-weight: 700;
  }

  .one-transaction > .row > div {
    padding-bottom: 10px;
  }

  .one-transaction {
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0.75rem;
    padding-bottom: 0.1rem;
    border: 1px solid #bbb;
    border-radius: 8px;
    margin-bottom: 12px;
  }

  .gray {
    color: #6a6a6a;
  }

  @media (max-width: 767px) {
  }
`
